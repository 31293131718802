import type { ComponentType } from "react"

const style = {
    scrollSnapType: "y mandatory",
    height: "100vh",
}

export const scrollSnapType = (Component): ComponentType => {
    return (props) => {
        return <Component {...props} style={style} />
    }
}
